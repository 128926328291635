export const classLevels = [{
    label: 'Beginner',
    level: 'beginner',
  },
  {
    label: 'Professional',

    level: 'professional',
  },
  {
    label: 'Intermediate',

    level: 'intermediate',
  },
  {
    label: 'Junior',

    level: 'junior',
  }];

  
 export const classSports = [
    { type: 'tennis', label: 'Tennis' },
    { type: 'badminton', label: 'Badminton' },
    { type: 'padel', label: 'Padel' },
  ];