const clothSizes = [{ value: 'S', label: 'S' }, { value: 'M', label: 'M' }, { value: 'L', label: 'L' }, { value: 'XL', label: 'XL' }, { value: 'XXL', label: 'XXL' }];
const colors = [{ value: 'white/red', label: 'white/red' },
  { value: 'blue/white', label: 'blue/White' },
  { value: 'black/yellow', label: 'black/yellow' },
  { value: 'black/white', label: 'black/white' },
  { value: 'black/yellow', label: 'black/yellow' },
  { value: 'black/blue', label: 'black/blue' },
  { value: 'gray/yellow', label: 'gray/yellow' },
  { value: 'pink/yellow', label: 'pink/yellow' },
  { value: 'red/blue', label: 'red/blue' },
  { value: 'red/black', label: 'red/black' },
  { value: 'dark-blue', label: 'dark blue' },
  { value: 'cyan-blue', label: 'cyan blue' },
  { value: 'angel blue', label: 'angel blue' },
  { value: 'autumn glory', label: 'autumn glory' },
  { value: 'white/red', label: 'white/red' },
  { value: 'black/fiesta-red', label: 'black/fiester red' },
  { value: 'black/poppy red', label: 'black/poppy red' },
  { value: 'fiesta red', label: 'Fiesta Red' },
  { value: 'blue/yellow', label: 'blue/Yellow Red' },
  { value: 'navy-blue/white', label: 'Navy blue/white' },
  { value: 'blue', label: 'blue' },
  { value: 'pink', label: 'pink' },
  { value: 'gray', label: 'gray' },
  { value: 'green', label: 'green' },
  { value: 'white', label: 'white' },
  { value: 'red', label: 'red' },
  { value: 'black', label: 'black' },
  { value: 'orange', label: 'orange' },
  { value: 'neutral', label: 'neutral' },
  { value: 'purple', label: 'purple' },
  { value: 'yellow', label: 'yellow' },
  { value: ' black/fiesta red', label: 'black/fiesta red' },
];
const shoeSizes = [{ value: '38', label: '38' }, { value: '40', label: '40' }, { value: '40.5', label: '40.5' }, { value: '41', label: '41' }, { value: '42', label: '42' }, { value: '42.5', label: '42.5' }, { value: '43', label: '43' }, { value: '44', label: '44' }, { value: '44.5', label: '44.5' }, { value: '45', label: '45' }, { value: '46', label: '46' }, { value: '46.5', label: '46.5' }, { value: '47', label: '47' }, { value: '48', label: '48' }];
const composition = [{ value: 'graphite', label: 'Graphite' }, { value: 'aluminium', label: 'Aluminium' }, { label: 'Carbon', value: 'carbon' }];
const gripSizes = [{ value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }, { value: '4', label: '4' }, { label: '5', value: '5' }, { value: '000', label: '000' }, { value: '5X0', label: '5X0' }, { value: '6X0', label: '6X0' }, { value: '7X0', label: '7X0' }, { value: '8X0', label: '8X0' }, { value: '125', label: '125' }, { value: '130', label: '130' }, { value: 'UNIQ', label: 'UNIQ' }, { value: 'X3', label: 'X3' }, { value: 'X6', label: 'X6' }, { value: 'X12', label: 'X12' }];
const headSizes = [{ value: '400', label: '400' }, { value: '523', label: '523' }, { value: '594', label: '594' }, { value: '619', label: '619' }, { value: '677', label: '677' }, { value: '630', label: '630' }, { value: '645', label: '645' }, { value: '660', label: '660' }, { value: '670', label: '670' }, { value: '680', label: '680' }, { label: '700', value: '700' }, { label: '742', value: '742' }];
const headShapes = [{ value: 'diamond', label: 'diamond' }, {value: "round", label: "round"}, {value: "teardrop", label: "teardrop"}, {value: "hybrid", label: "hybrid"}];
const balanceTypes = [{ value: 'head_heavy', label: 'Head Heavy' }];
const recommendedGrip = [{ value: 'syntec_pro', label: 'Syntec Pro' }];
const length = [{ value: '432', label: '432' }, { value: '483', label: '483' }, { value: '534', label: '534' }, { value: '584', label: '584' }, { value: '635', label: '635' }, { value: '685', label: '685' }, { value: '700', label: '700' }];
const strung = [{ value: 'strung', label: 'Strung' }, { value: 'unstrung', label: 'Unstrung' }];
export const locations = [{ value: 'abuja', label: 'Abuja' }, { value: 'lagos', label: 'Lagos' }];
export const playType = [{ value: 'versatile', label: 'versatile' }, { value: 'air_striker', label: 'Air Striker' }, { value: 'offensive', label: 'Offensive' }, { value: 'counter_striker', label: 'Counter Striker' }, { label: 'Technical Striker', value: 'technical_striker' }];
export {
  clothSizes, recommendedGrip, balanceTypes, colors, shoeSizes, composition, strung, headShapes, gripSizes, headSizes, length,
};
