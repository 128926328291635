import React from 'react';

const HomeEquipmentInfo = () => (
  <div className="banner-text full-screen my-3 text-center p-8 md:p-20 leading-9">
    <h2 className="text-2xl tracking-wider font-medium">
      Tennis Equipment & Supplies
    </h2>
    <h3 className="text-lg font-medium">Love Racquets Sports?</h3>
    <p className="text-pry font-semibold">
      We specialize in Babolat tennis, Badminton and Padel equipment, offering a wide range of rackets, strings, bags, and accessories to help you perform at your best. Our expert staff are passionate about the sport and can guide you through our selections to find the perfect gear for your game. Come in and experience the power and precision of Babolat, exclusively at our retail store.

    </p>
  </div>
);

export default HomeEquipmentInfo;
